import React from 'react';
import BannerSection from '../components/AccountAgreement/BannerSection';
import Layout from '../components/Layout/Layout';
import PrivacyContentSection from '../components/Privacy/PrivacyContentSection';

const pageInfo = {
  path: '/privacy',
  title: 'About your privacy',
  description:
    'The Charitable Impact Site and the App are operated by CHIMP Technology Inc. and allow you to use and receive  services offered by CHIMP Services Inc. and CHIMP: Charitable Impact Foundation (Canada).',
  isNavbar: false,
  isFooter: false,
};

const Privacy = () => {
  return (
    <Layout pageInfo={pageInfo}>
      <BannerSection bannerText='About your privacy' />
      <PrivacyContentSection />
    </Layout>
  );
};

export default Privacy;
